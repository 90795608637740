<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Inventory Requests
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left booking_content_wrapper mb-2"
        cols="12"
        md="12"
      >
        <!-- <b-col
          class="content-header-right mb-2 ml-auto"
          cols="12"
          md="12"
        >
          <b-button
            v-if="canViewThisAction('create', 'InventoryRequest')"
            variant="success"
            :to="{ name: 'operation-service-forms-create' }"
            class="mobile_create_button"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle d-md-block d-none">Create New Inventory Request</span>
            <span class="align-middle d-md-none d-block">New</span>
          </b-button>
        </b-col> -->
        <b-dropdown
          v-if="isMobile()"
          :text="currentTabTitle"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="(tab, index) in pageTabs"
            :key="index"
            @click="changeRoute(tab.param)"
          >
            {{ tab.title }} ({{ tab.count }})
          </b-dropdown-item>
        </b-dropdown>
        <b-tabs
          pills
          class="booking__content"
          :nav-class="[isMobile() ? 'd-none' : '']"
        >
          <!-- Tab: Account -->
          <b-tab
            :active="$route.params.type == 'open'"
            lazy
            @click="changeRoute('open')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Open <span class="rounded-number">{{ openCount }}</span></span>
            </template>
            <open-inventory-request
              class="mt-2 pt-75"
              :duty-officer-options="dutyOfficerOptions"
              :service-form-options="serviceFormOptions"
              :store-options="storeOptions"
              @emitCountInventoryRequest="countInventoryRequests"
            />
          </b-tab>

          <b-tab
            :active="$route.params.type == 'collected'"
            lazy
            @click="changeRoute('collected')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Collected <span class="rounded-number">{{ collectedCount }}</span></span>
            </template>
            <collected-inventory-request
              class="mt-2 pt-75"
              :duty-officer-options="dutyOfficerOptions"
              :service-form-options="serviceFormOptions"
              :store-options="storeOptions"
              @emitCountInventoryRequest="countInventoryRequests"
            />
          </b-tab>

          <b-tab
            :active="$route.params.type == 'completed'"
            lazy
            @click="changeRoute('completed')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Completed <span class="rounded-number">{{ completedCount }}</span></span>
            </template>
            <completed-inventory-request
              class="mt-2 pt-75"
              :duty-officer-options="dutyOfficerOptions"
              :service-form-options="serviceFormOptions"
              :store-options="storeOptions"
              @emitCountInventoryRequest="countInventoryRequests"
            />
          </b-tab>

          <b-tab
            :active="$route.params.type == 'returned'"
            lazy
            @click="changeRoute('returned')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Returned <span class="rounded-number">{{ returnedCount }}</span></span>
            </template>
            <returned-inventory-request
              class="mt-2 pt-75"
              :duty-officer-options="dutyOfficerOptions"
              :service-form-options="serviceFormOptions"
              :store-options="storeOptions"
              @emitCountInventoryRequest="countInventoryRequests"
            />
          </b-tab>

          <b-tab
            :active="$route.params.type == 'cancelled'"
            lazy
            @click="changeRoute('cancelled')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Cancelled <span class="rounded-number">{{ cancelledCount }}</span></span>
            </template>
            <cancelled-inventory-request
              class="mt-2 pt-75"
              :duty-officer-options="dutyOfficerOptions"
              :service-form-options="serviceFormOptions"
              :store-options="storeOptions"
              @emitCountInventoryRequest="countInventoryRequests"
            />
          </b-tab>

          <b-tab
            :active="$route.params.type == 'all-requests'"
            lazy
            @click="changeRoute('all-requests')"
          >
            <template #title>
              <span class="d-none d-sm-inline">All Inventory Requests <span class="rounded-number">{{ allCount }}</span></span>
            </template>
            <all-inventory-request
              class="mt-2 pt-75"
              :duty-officer-options="dutyOfficerOptions"
              :service-form-options="serviceFormOptions"
              :store-options="storeOptions"
              @emitCountInventoryRequest="countInventoryRequests"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BTab, BTabs, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AllInventoryRequest from './all-inventory-request/AllInventoryRequest.vue'
import OpenInventoryRequest from './open-inventory-request/OpenInventoryRequest.vue'
import CompletedInventoryRequest from './completed-inventory-request/CompletedInventoryRequest.vue'
import CancelledInventoryRequest from './cancelled-inventory-request/CancelledInventoryRequest.vue'
import ReturnedInventoryRequest from './returned-inventory-request/ReturnedInventoryRequest.vue'
import CollectedInventoryRequest from './collected-inventory-request/CollectedInventoryRequest.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BRow,
    BCol,
    // BButton,
    BTab,
    BTabs,
    BDropdown,
    BDropdownItem,

    AllInventoryRequest,
    OpenInventoryRequest,
    CompletedInventoryRequest,
    CancelledInventoryRequest,
    ReturnedInventoryRequest,
    CollectedInventoryRequest,
  },
  data() {
    return {
      canViewThisAction,
      currentTabTitle: '',
      allCount: 0,
      openCount: 0,
      collectedCount: 0,
      completedCount: 0,
      returnedCount: 0,
      cancelledCount: 0,
      dutyOfficerOptions: [],
      serviceFormOptions: [],
      storeOptions: [],
      pageTabs: [
        {
          title: 'Open',
          param: 'open',
          count: 0,
        },
        {
          title: 'Collected',
          param: 'collected',
          count: 0,
        },
        {
          title: 'Completed',
          param: 'completed',
          count: 0,
        },
        {
          title: 'Returned',
          param: 'returned',
          count: 0,
        },
        {
          title: 'Cancelled',
          param: 'cancelled',
          count: 0,
        },
        {
          title: 'All Inventory Requests',
          param: 'all-requests',
          count: 0,
        },
      ],
    }
  },
  watch: {
    $route() {
      const param = this.$route.params.type
      const currentTab = this.pageTabs.find(o => o.param === param)
      if (currentTab) {
        this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
      }
    },
  },
  beforeMount() {
    this.countInventoryRequests()
    this.$http.get('inventory/inventory-request/respond-with/duty-officers-store')
      .then(response => {
        this.dutyOfficerOptions = response.data.dutyOfficers || []
        this.serviceFormOptions = response.data.serviceFormOptions || []
        this.storeOptions = response.data.storeOptions || []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    changeRoute(param) {
      this.$router.replace({
        params: {
          type: param,
        },
        query: {},
      })
    },
    countInventoryRequests() {
      this.$http.get('inventory/inventory-request/counts')
        .then(response => {
          this.allCount = response.data.allCount
          this.cancelledCount = response.data.cancelledCount
          this.returnedCount = response.data.returnedCount
          this.completedCount = response.data.completedCount
          this.collectedCount = response.data.collectedCount
          this.openCount = response.data.openCount

          this.pageTabs.forEach(element => {
            if (element.title === 'All Inventory Requests') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.allCount
            } else if (element.title === 'Cancelled') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.cancelledCount
            } else if (element.title === 'Returned') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.returnedCount
            } else if (element.title === 'Completed') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.completedCount
            } else if (element.title === 'Collected') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.collectedCount
            } else if (element.title === 'Open') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.openCount
            }
          })

          const param = this.$route.params.type

          const currentTab = this.pageTabs.find(o => o.param === param)
          if (currentTab) {
            this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>
  .rounded-number {
    height: 24px;
    width: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    color:#104D9D;
  }
</style>
